/* eslint-disable camelcase */
import addRocEventHandler from 'shared/util/events/rocEventUtils';
import gtag from 'features/tracking/gtag';
import { CheckoutStep } from 'features/commerce/checkout/types/checkout-steps';
import { Order } from 'features/commerce/shopping-cart/types';

interface GoogleAnalyticsOrderItem {
	id: string;
	name: string;
	quantity: number;
	price: number | undefined;
}

/**
 * Helper function for converting order model to the list of items in format expected by Google Analytics
 * @param order
 */
function convertOrderToGoogleAnalyticItems(order: Order): Array<GoogleAnalyticsOrderItem> {
	return order.recipients.reduce(
		(items, recipient) => [
			...items,
			...recipient.items.map((item) => {
				return {
					id: item.product.id,
					name: item.name,
					quantity: item.quantity,
					price:
						item.unitPrice && item.unitPrice.salePrice
							? item.unitPrice.salePrice.rawValue
							: item.unitPrice?.basePrice.rawValue,
				};
			}),
		],
		[],
	);
}

export default function setup() {
	addRocEventHandler('added-to-cart', ({ items }) => {
		// "add_to_cart" event should be triggered when a user adds items to cart
		gtag('event', 'add_to_cart', {
			items: items.map((item) => {
				return {
					id: item.sku, // "id: field can be set either to the product ID or SKU
					name: item.name,
					quantity: item.adjustedQuantity,
					price: item.price,
				};
			}),
		});
	});

	addRocEventHandler('product-viewed', ({ productId, productName }) => {
		// "view_item" event should be triggered when a user views product or item details.
		gtag('event', 'view_item', {
			items: [
				{
					id: productId,
					name: productName,
				},
			],
		});
	});

	addRocEventHandler('checkout-step-changed', ({ step, cart }) => {
		const items = convertOrderToGoogleAnalyticItems(cart);
		switch (step) {
			case CheckoutStep.Loading:
				// "begin_checkout" event should be triggered when a user begins checkout.
				// in ROC Commerce this would be CheckoutStep.Loading as this is the entry point to the checkout.
				gtag('event', 'begin_checkout', {
					items: items,
				});
				break;
			case CheckoutStep.LoginOrGuest:
			case CheckoutStep.ShippingAddress:
			case CheckoutStep.ShippingMethod:
			case CheckoutStep.ShippingReview:
			case CheckoutStep.Payment:
			case CheckoutStep.Review:
				// "checkout_progress" event should be triggered when a user completes a checkout step.
				// that's why we have to do some math because ROC dispatched "checkout-step-changed" event and provides
				// number of the current step, but we have to get the previous step that was completed.
				gtag('event', 'checkout_progress', {
					checkout_step: step,
					items: items,
				});
				break;
		}
	});

	addRocEventHandler('order-placed', ({ order }) => {
		gtag('event', 'purchase', {
			transaction_id: order.orderNo,
			value: order.total.basePrice.rawValue,
			tax: order.tax?.basePrice.rawValue,
			shipping: order.shippingTotal.basePrice.rawValue,
			items: convertOrderToGoogleAnalyticItems(order),
		});
	});

	addRocEventHandler('checkout-option-changed', ({ step, option }) => {
		gtag('event', 'set_checkout_option', {
			checkout_step: step,
			checkout_option: option,
		});
	});

	addRocEventHandler('product-clicked', ({ productId, productName }) => {
		gtag('event', 'select_content', {
			content_type: 'product',
			items: [
				{
					id: productId,
					name: productName,
				},
			],
		});
	});
}
